/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

const GET_COMPONENT = gql`
  query component($id: ID!) {
    component(id: $id) {
      id
      category {
        name
      }
      ShortDescription
      LongDescription
      cover {
        url
      }
      name
    }
  }
`;

export { GET_COMPONENT };
