const colors = {
  lightBeige: '#fffcfa',
  darkBeige: '#f3ded4',
  darkBlue: '#202675',
  lightGrey: '#f1f1f1',
  mediumGrey: '#c3c4c7',
  darkGrey: '#222222',
  black: '#191919',
  lightOrange: '#f57e20',
  lightPurple: '#773b97',
  greyBkdg: '#f8f8f8',
  greyBorder: '#f3f3f3',
};

export default colors;
