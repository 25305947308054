import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
/**
 *
 * GetStarted
 *
 */

import React from 'react';
import H1 from '../H1';
import DemoButton from '../DemoButton';

import StyledGetStarted from './StyledGetStarted';

function GetStarted() {
  return (
    <StyledGetStarted>
      <div className="informations-wrapper background-grey">
        <div className="container section-get-started">
          <div className="get-started-textbox">
            <H1 className="ready-text">Ready to get started?</H1>
            <H1>Get in touch or book a demo!</H1>
          </div>
          <div className="get-started-button">
            <DemoButton />
            <Button href="/contact" className="contact-button">
              Contact us
            </Button>
          </div>
        </div>
      </div>
    </StyledGetStarted>
  );
}

export default GetStarted;
