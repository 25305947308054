import { createGlobalStyle } from 'styled-components';

import colors from '../../assets/styles/colors';
import fonts from '../../assets/styles/fonts';
import mixins from '../../assets/styles/mixins';
import sizes from '../../assets/styles/sizes';

const General = createGlobalStyle`
  * { ${mixins().bbox} }
  html, body { -webkit-font-smoothing: antialiased; }

  body {
    a, button, button:focus {
      cursor: pointer;
      outline: 0;
    }
    a, p, h1, h2, h3, h4, h5, table, ul {
      margin-bottom: 0;
      line-height: 1.5rem;
      ${fonts.reg}
    }
    span {
      line-height: normal;
    }
    .learnmore-link {
      color: ${colors.lightPurple};
      font-size: 15px !important;
      display: block;
      text-transform: none;
      font-weight: 700;
    }
    .container, ul {
      padding: 0;
    }
    .container {
      @media (min-width: ${sizes.wide}) {
        max-width: 1240px;
      }
    }
    ul {
      list-style-type: none;
      margin-bottom: 0;
    }
    .link-wrapper {
      a.link {
        display: table;
        float: right;
      }
    }
    a.link {
      cursor: pointer;
      p {
        font-size: 10px;    
        line-height: 22px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
    .page-wrapper {
      position: relative;
    }
    .loading-box {
      padding: 100em;
    }
  }
  .navbar-brand {
    font-size: 0;
    padding: 0;
    img {
      width: ${sizes.header.logoWidth.small};
      height: auto;
    }
    @media (min-width: ${sizes.tablet}) {
      img {
        width: ${sizes.header.logoWidth.large};
      }
    }
  }
  .background-white {
    background-color: white;
  }
  .background-grey {
    background-color: ${colors.greyBkdg};
  }
  .background-lightGrey {
    background-color: ${colors.lightGrey};
  }
  .background-mediumGrey {
    background-color: ${colors.mediumGrey};
  }
  .background-darkGrey {
    background-color: ${colors.darkGrey};
  }
  .background-Orange {
    background-color: ${colors.lightOrange};
  }
  .background-Purple {
    background-color: ${colors.lightPurple};
  }
  .text-orange, .text-orange:hover {
    color: ${colors.lightOrange};
  }
  .text-white, .text-white:hover {
    color: white;
  }
  .styled-button-box {
    margin-top: 2em;
    margin-bottom: 15px;
  }
  .styled-button {
    padding: 15px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  }
  .mobile-only {
    display: inline-block !important;
  }
  .desktop-only {
    display: none !important;
  }
  .zammad-form {
    h2 {
      margin-bottom: 1em;
    }
  }
  @media (min-width: ${sizes.tablet}) {
    .mobile-only {
      display: none !important;
    }
    .desktop-only {
      display: inline-block !important;
    }
  }
`;

export default General;
