import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const Prices = createGlobalStyle`
  #prices-page {
    h1 {
      margin-bottom: ${sizes.margin * 2}px;
    }
    h4 {
      margin-bottom: ${sizes.margin * 1.6}px;
    }
    .text-section {
      padding-bottom: 5em;
      p {
        font-size: 1.05em;
        line-height: 1.5;
        color: ${colors.black};
      }
    }
    ul {
      list-style-type: initial;
      margin-top: ${sizes.margin * 1.6}px;
      margin-left: ${sizes.margin * 1.8}px;
    }
    .cover-wrapper, .informations-wrapper {
      position: relative;
    }
    .cover-wrapper {
      z-index: 0;
      background-color: ${colors.lightOrange};
      padding: 5rem ${sizes.margin * 1.8}px 5rem;
      h1 {
        font-size: 2.75em;
      }
      p {
        font-size: 1.25em;
        line-height: 2rem;
      }
      .cover-text {
        max-width: 800px;
      }
      .background {
        width: 100%;
        height: 60vh;
        background-color: ${colors.greyBkdg};
        position: absolute;
        top: 0;
        left: 0;
        z-index: -100;
      }
      .price-wrapper {
        margin-top: 5em;
        text-align: center;
        h1 {
          line-height: 0.75em;
        }
        p {
          font-size: 1em;
          line-height: 1.25em;
          font-weight: 300;
        }
        .price-column {
          width: 100%;
          vertical-align: top;
          display: inline-block;
          padding: 2em;
          box-shadow: 0 50px 50px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3);
          h1 {
            font-size: 2em;
          }
          h4 {
            font-size: 1em;
          }
          p {
            font-size: 0.75em;
          }
          .price-content {
            width: 45%;
            display: inline-block;
            vertical-align: middle;
          }
          .price-separator {
            width: 10%;
            display: inline-block;
            vertical-align: middle;
          }
          .min-fee {
            font-size: 0.75em;
            margin-top: 0.5em;
            line-height: 1.5em;
          }
        }
        .price-column-1 {
          border-radius: 0.2em 0.2em 0 0;
        }
        .price-column-2 {
          border-radius: 0 0 0.2em 0.2em;
        }
      }
      .terms-box {
        margin-top: 3em;
        font-size: 0.75em;
      }
    }
    .informations-wrapper {
      z-index: 1;
      padding: 0 ${sizes.margin * 1.8}px 0;
      .price-section {
        padding-top: 5em;
        .support-plan-table {
          .table-title-column {
            width: 17.5%;
          }
          .table-cell-column {
            width: 27.5%;
          }
        }
      }
      .container .text-section .styled-button-box .get-started-buttons .demo-button-wrapper .demo-button {
        background-color: white;
        color: ${colors.lightOrange};
      }
    }
    @media (min-width: ${sizes.tablet}) {
      .cover-wrapper {
        .price-wrapper {
          .price-column {
            width: 50%;
            h1 {
              font-size: 2.25em;
            }
            h4 {
              font-size: 1.25em;
            }
            p {
              font-size: 0.875em;
            }
          }
          .price-column-1 {
            border-radius: 0.2em 0 0 0.2em;
           }
          .price-column-2 {
            border-radius: 0 0.2em 0.2em 0;
          }
        }
      }
    }
    @media (min-width: ${sizes.desktop}) {
      .cover-wrapper {
        .price-wrapper {
          .price-column {
            width: 50%;
            h1 {
              font-size: 2.75em;
            }
            h4 {
              font-size: 1.5em;
            }
            p {
              font-size: 1em;
            }
          }
          .price-column-1 {
            border-radius: 0.2em 0 0 0.2em;
           }
          .price-column-2 {
            border-radius: 0 0.2em 0.2em 0;
          }
        }
      }

    }
  }
`;

export default Prices;
