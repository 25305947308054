import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const About = createGlobalStyle`
  #home-page {
    h1 {
      margin-bottom: ${sizes.margin * 2}px;
    }
    h4 {
      margin-bottom: ${sizes.margin * 1.6}px;
    }
    .text-section {
      p {
        font-size: 1.05em;
        line-height: 1.5;
        color: ${colors.black};
        padding-bottom: 10px;
      }
    }
    ul {
      list-style-type: initial;
      margin-top: ${sizes.margin * 1.6}px;
      margin-left: ${sizes.margin * 1.8}px;
    }
    .cover-wrapper, .informations-wrapper {
      position: relative;
    }
    .cover-wrapper {
      z-index: 0;
      background-color: ${colors.greyBkdg};
      padding: 0 ${sizes.margin * 1.8}px 0;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1em;
        line-height: 1.5rem;
        padding-bottom: 5px;
      }
      .cover-text {
        padding-top: 3rem;
        .demo-button {
          padding: 15px;
          text-transform: uppercase;
          color: white;
          background-color: ${colors.lightOrange};
          border-radius: 4px;
          font-weight: 700;
          box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        }
        .demo-button-box {
          margin-top: 30px;
          margin-bottom: 15px;
        }
      }
      .cover-media {
        padding: 3rem 4rem 3rem;
        vertical-align: top;
      }
    }
    .informations-wrapper {
      z-index: 1;
      padding: 0 ${sizes.margin * 1.8}px 0;
      .container {
        padding: 5rem 0 5rem;
      }
      .column-1 {
        vertical-align: top;
      }
      .column-2 {
        vertical-align: top;
      }
      .network-image {
        padding: 2rem 4rem 0;
      }
      .benefits-section {
        text-align: center;
        h1 {
          margin-bottom: 1em;
        }
        .column {
          width: 50%;
          display: inline-block;
          text-align: left;
          vertical-align: top;
          padding-right: 1em;
          img {
            padding: 0 2em 0em 0;
          }
        }
        .demo-button-box {
          margin-top: 5em;
          margin-bottom: 15px;
        }
        .demo-button {
          padding: 15px;
          text-transform: uppercase;
          color: white;
          background-color: ${colors.lightOrange};
          border-radius: 4px;
          font-weight: 700;
          box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        }
      }
      .section-standard-1 {
        p {
          max-width: 800px;
        }
        a {
          padding: 15px 0 15px;
        }
      }
      .section-standard-2 {
        .column-1 {
        }
        .column-2 {
          padding-top: 2rem;
          a {
            padding: 15px 0 10px;
          }
        }
      }
      .section-standard-3 {
        a {
          padding: 15px 0 15px;
        }
        .column-1 {
          padding-top: 10px;
        }
        .column-2 {
          padding-top: 2rem;
        }
      }
      .section-ecosystem {
        text-align: center;
        max-width: 800px;
        .logo-img {
          height: auto;
          width: 25%;
          filter: grayscale(100%);
          margin: 2rem 2rem 0 2rem;
        }
      }
      .section-testimonial {
        text-align: center;
        .testimonial-img {
          border-radius: 50%;
          border: 0.5rem solid ${colors.lightOrange}
          height: 10rem; 
          width: 10rem;
          margin: 0 auto;
          img {
            filter: grayscale(100%);
            border-radius: 50%;
          }
        }
        h1 {
          margin-top: 1em;
        }
      }
    }
    .mail-link {
      display: table;
      p {
        color: ${colors.darkBlue};
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: ${sizes.tablet}) {
      .informations-wrapper {
        .benefits-section {
          h4 {
            font-size: 1rem !important;
          }
          p {
            font-size: 0.8rem !important;
          }
          .column-1 {
            padding-right: 0.5em !important;
          }
          .column-2 {
            padding-right: 0em !important;
            padding-left: 0.5em;
          }
          .column-3 {
            padding-right: 0.5em !important;
          }
          .column-4 {
            padding-right: 0em !important;
            padding-left: 0.5em;
          }
        }
      }
    }

    @media (min-width: ${sizes.tablet}) {
      .cover-wrapper {
        h1 {
          font-size: 1.75rem;
        }
        p {
          font-size: 1.05em;
          line-height: 1.5rem;
        }
        a {
          font-size: 1em;
          line-height: 2rem;
        }
        .cover-text {
          padding-top: 5.25rem;
          width: 50%;
          display: inline-block;
          padding-right: 0;
        }
        .cover-media {
          width: 50%;
          display: inline-block;
          padding: 5rem 0 5rem 1rem;
        }
      }
      .informations-wrapper {
        h1 {
          font-size: 1.75rem;
        }
        .benefits-section {
          h4 {
            font-size: 1.1rem;
          }
          .column {
            text-align: left;
            width: 50%;
            padding: 1em 1em 0;
            img {
              padding: 0 5em 0 3em;
            }
          }
        }
        .section-standard-1 {
          h1 {
            padding-top: 10px;
          }
        }
        .section-standard-2 {
          .column-1 {
            width: 50%;
            padding: 1.5rem 2rem 0 0;
            display: inline-block;
          }
          .column-2 {
            width: 50%;
            padding: 0 0 0 1rem;
            display: inline-block;
          }
        }
        .section-standard-3 {
          .column-1 {
            width: 50%;
            padding-right: 1rem;
            display: inline-block;
          }
          .column-2 {
            width: 50%;
            padding: 10px 0 0 1rem;
            display: inline-block;
          }
        }
        .section-testimonial {
        }
      }
    }
    @media (min-width: ${sizes.desktop}) {
      .cover-wrapper {
        h1 {
          font-size: 2.25em;
        }
        p {
          font-size: 1.05em;
          line-height: 1.5rem;
        }
        a {
          font-size: 1em;
          line-height: 2rem;
        }
        .cover-text {
          padding-top: 5.5rem;
          width: 50%;
          padding-right: 1rem;
        }
        .cover-media {
          width: 50%;
          padding: 3.5rem 0 5rem 6rem;
        }
      }
      .informations-wrapper {
        h1 {
          font-size: 2em;
        }
        .benefits-section {
          .column {
            width: 25%;
            img {
              padding: 0 2em 0em 1em;
            }
          }
        }
        .section-standard-2 {
          .column-1 {
            width: 40%;
            padding: 0 3rem 0 0;
            vertical-align: top;
          }
          .column-2 {
            padding: 2.5rem 0 0 0;
            width: 60%;
          }
        }
        .section-standard-3 {
          .column-1 {
            padding-right: 2rem;
          }
          .column-2 {
            padding-left: 2rem;
          }
      }
    }
    @media (min-width: ${sizes.wide}) {
      .cover-wrapper {
        h1 {
          font-size: 2.75em;
        }
        p {
          font-size: 1.05em;
          line-height: 1.5rem;
        }
        a {
          font-size: 1em;
          line-height: 2rem;
        }
        .cover-text {
          padding-top: 9rem;
          width: 50%;
          padding-right: 3rem;
          display: inline-block;
        }
        .cover-media {
          width: 50%;
          padding: 3rem 0 rem 6rem;
          display: inline-block;
        }
      }
      .informations-wrapper {
        h1 {
          font-size: 2.25em;
        }
        .benefits-section {
          .column {
            img {
              padding: 0 3em 0em 2em;
            }
          }
        }
        .section-standard-2 {
          .column-1 {
            padding-right: 5rem;
          }
          .column-2 {
            width: 60%;
            padding: 6.5rem 0 0 0;
          }
        }
      }
    }
  }
`;

export default About;
