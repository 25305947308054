/**
 *
 * H4
 *
 */

import styled from 'styled-components';
import colors from '../../assets/styles/colors';

const H4 = styled.h4`
  font-size: 16px;
  color: ${colors.black};
`;

export default H4;
