/**
 *
 * NotFound
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';

function NotFound() {
  return (
    <div>
      NotFound
    </div>
  );
}

NotFound.defaultProps = {};
NotFound.propTypes = {};

export default NotFound;
