import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const Stack = createGlobalStyle`
  #stack-page {
    h1 {
      margin-bottom: ${sizes.margin * 2}px;
    }
    h4 {
      margin-bottom: ${sizes.margin * 1.6}px;
    }
    .text-section {
      padding-bottom: ${sizes.margin * 7.4}px;
      p {
        font-size: 1.05em;
        line-height: 1.5;
        color: ${colors.black};
      }
    }
    ul {
      list-style-type: initial;
      margin-top: ${sizes.margin * 1.6}px;
      margin-left: ${sizes.margin * 1.8}px;
    }
    .cover-wrapper, .informations-wrapper {
      position: relative;
    }
    .cover-wrapper {
      z-index: 0;
      background-color: ${colors.greyBkdg};
      padding: 5rem ${sizes.margin * 1.8}px 5rem;
      h1 {
        font-size: 2.75em;
      }
      p {
        font-size: 1.25em;
        line-height: 2rem;
      }
      .cover-text {
        max-width: 800px;
      }
    }
    .informations-wrapper {
      padding: 0 ${sizes.margin * 1.8}px 0;
      overflow: visible;
      background-color: ${colors.white};
      h1 {
        padding: 0 0 ${sizes.margin * 1.4}px 0;
      }
      h4 {
        padding-bottom: 10px;
      }
      img {
        max-width: 100%;
      }
      .container {
        padding: 5em 0;
      }
      .core-container {
        .core-item {
          vertical-align: top;
          width: 50%;
          display: inline-block;
          padding: 1em 1em 0 0;
          img {
            width: 50%;
          }
        }
        .core-item-2 {
          padding: 1em 0 0 1em;
        }
      }
      .screenshot {
        text-align: center;
        padding: 3em 0 6em 0;
        img {
          box-shadow: 0 50px 50px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3);
          height: auto;
          max-width: 100%;
          border-radius: 0.5em;
        }
      }
      .api-container .right-content {
        overflow: hidden;
        padding-top: 3em;
      }
    }
    @media (min-width: ${sizes.tablet}) {
      .cover-wrapper {
      }
      .informations-wrapper {
        .core-container {
          .core-item {
            width: 25%;
            padding: 2em 2em 0 0;
            img {
              width: 66%;
            }
          }
          .core-item-2 {
            padding: 2em 2em 0 0;
          }
        }
        .content-left {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          padding: 2em 1em 2em 0;
        }
        .content-left.screenshot {
          transform: perspective(1000px) scale(1) rotateX(2deg) rotateY(13deg) rotateZ(0deg);
          transform-origin: center right;
        }
        .content-right {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          padding: 2em 0 2em 1em;
        }
        .content-right.screenshot {
          transform: perspective(1000px) scale(0.88) rotateX(2deg) rotateY(-13deg);
          transform-origin: center left;
        }
        .screenshot {
          img {
            max-width: 80%;
          }
        }
        .mobile-screenshot {
          img {
          }
        }
        .api-container {
          display: flex;
          align-items: center;
          .left-content {
            width: 50%;
            display: inline-block;
          }
          .right-content {
            width: 50%;
            display: inline-block;
            padding-left: 3em;
          }
        }
      }
    }
    @media (min-width: ${sizes.wide}) {
      .informations-wrapper {
        .core-container {
          .core-item {
            width: 25%;
            padding: 2em 2em 0 0;
            img {
              width: 33%;
            }
          }
        }
        .content-left {
          padding: 1em 1em 1em 0;
        }
        .content-right {
          padding: 1em 0 1em 1em;
        }
      }
    }
  }
`;

export default Stack;
