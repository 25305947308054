import React from 'react';
 
import Embed from 'runkit-embed-react';
 
class Example extends React.Component {
  render() {
    return <Embed source={`var result = await getJSON("https://api.sandbox.braveno.com/products");
result;`}
preamble={ `var getJSON = require("async-get-json");`}
    />;
  }
}

export default Example;
