/**
 *
 * Contact
 *
 */

import React from 'react';
import ZammadButton from '../../components/ZammadButton';
import ZammadSalesButton from '../../components/ZammadSalesButton';
import ZammadMediaButton from '../../components/ZammadMediaButton';
import ZammadGeneralButton from '../../components/ZammadGeneralButton';

import H1 from '../../components/H1';

function Contact() {
  return (
    <div className="page-wrapper" id="contact-page">
      <div className="cover-wrapper">
        <div className="container">
          <H1>Contacts</H1>
          <p>
            To make sure you speak to the right person, please select what you’d like to talk about.
          </p>
        </div>
      </div>
      <div className="informations-wrapper">
        <div className="container">
          <ZammadSalesButton />
          <ZammadButton />
          <ZammadGeneralButton />
          <ZammadMediaButton />
        </div>
      </div>
    </div>
  );
}

export default Contact;
