import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const About = createGlobalStyle`
  #about-page {
    h1 {
      margin-bottom: ${sizes.margin * 2}px;
    }
    h4 {
      margin-bottom: ${sizes.margin * 1.6}px;
    }
    .text-section {
      padding-bottom: ${sizes.margin * 7.4}px;
      p {
        font-size: 1.05em;
        line-height: 1.5;
        color: ${colors.black};
      }
    }
    ul {
      list-style-type: initial;
      margin-top: ${sizes.margin * 1.6}px;
      margin-left: ${sizes.margin * 1.8}px;
    }
    .cover-wrapper, .informations-wrapper {
      position: relative;
    }
    .cover-wrapper {
      z-index: 0;
      background-color: ${colors.greyBkdg};
      padding: 5rem ${sizes.margin * 1.8}px 5rem;
      h1 {
        font-size: 2.75em;
      }
      p {
        font-size: 1.25em;
        line-height: 2rem;
      }
      .cover-text {
        max-width: 800px;
      }
    }
    .informations-wrapper {
      z-index: 1;
      padding: 0 ${sizes.margin * 1.8}px 0;
      .about-content {
        margin-top: 7em;
        text-align: center;
      }
      .story-section {
        background-color: ${colors.greyBkdg};
        margin: 4em 0 8em;
        text-align: center;
        overflow: hidden;
        hr {
          margin: 2em 0;
        }
        .left-story {
          display: inline-block;
          padding: 2em;
          text-align: left;
          vertical-align: top;
        }
        .right-story {
          display: inline-block;
          background-color: ${colors.lightGrey};
          padding: 2em;
          text-align: left;
          vertical-align: top;
          padding-bottom: 52em;
          margin-bottom: -50em;
        }
      }
      .people-section {
        margin: 4em 0 8em;
        text-align: center;
        .people-img-section {
          .people-img {
            width: 50%;
            height: 10em;
            filter: grayscale(100%);
          }
        }
      }
    }
    @media (min-width: ${sizes.tablet}) {
      .cover-wrapper {
      }
      .informations-wrapper {
        .container {
          .left-story {
            width: 50%;
          }
          .right-story {
            width: 50%;
          }
        }
        .people-section {
          margin: 4em 0 8em;
          text-align: center;
          .people-img-section {
            .people-img {
              width: 25%;
              height: 15em;
            }
          }
        }
      }
    }
    @media (min-width: ${sizes.desktop}) {
      .cover-wrapper {
      }
    }
  }
`;

export default About;
