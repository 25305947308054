/**
 *
 * ComponentPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { GET_COMPONENT } from '../../queries';
import Query from '../../components/Query';
import GetStarted from '../../components/GetStarted';

import RenderView from './RenderView';

function ComponentPage(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  /* istanbul ignore next */
  const renderView = ({ component, ...rest }) => {
    return <RenderView component={component} rest={rest} {...props} />;
  };

  return (
    <div className="page-wrapper">
      <div id="component-page">
        <Query
          query={GET_COMPONENT}
          render={renderView}
          variables={{ id, reviewsFilter: { component: id } }}
        />
      </div>
      <GetStarted />
    </div>
  );
}

ComponentPage.defaultProps = {};
ComponentPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ComponentPage;
