import React from 'react';
import PropTypes from 'prop-types';

import marked from 'marked';
import ReactHtmlParser from 'react-html-parser';
import Grid from '../../components/Grid';
import CardSection from '../../components/ComponentCardSection';

const RenderView = ({ component, history }) => {
  const { LongDescription } = component;
  const parsedContent = marked(LongDescription || ' ');

  return (
    <div className="container">
      {/* <div className="back-wrapper">
        <a href="/components" className="back-link">
          &lt; Back to components
        </a>
      </div> */}
      <div className="intro-wrapper">
        <Grid>
          <li className="intro-section">
            <CardSection
              component={{ ...component }}
              hasLink
              history={history}
            />
          </li>
        </Grid>
      </div>
      <div className="informations-wrapper">
        {ReactHtmlParser(parsedContent)}
      </div>
    </div>
  );
};

RenderView.defaultProps = {
  component: {
    cover: [],
    district: null,
    price: null,
  },
};

RenderView.propTypes = {
  component: PropTypes.shape({
    cover: PropTypes.array,
    district: PropTypes.string,
    price: PropTypes.string,
  }),
  history: PropTypes.object.isRequired,
};

export default RenderView;
