/**
 *
 * DemoButton
 *
 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import CalendlyEmbed from '../CalendlyEmbed';

import StyledDemoButton from './StyledDemoButton';

function DemoButton(props) {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <StyledDemoButton className="get-started-buttons">
      <div className="demo-button-wrapper">
        <Button onClick={toggle} role="button" className="demo-button">
          Book a demo
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <CalendlyEmbed account="braveno" eventName="demo" />
          </ModalBody>
        </Modal>
      </div>
    </StyledDemoButton>
  );
}

export default DemoButton;
