/**
 *
 * Loading
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Loading() {
  return (
    <div className="loading-box">
      Loading
    </div>
  );
}

Loading.defaultProps = {};
Loading.propTypes = {};

export default Loading;
