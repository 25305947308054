/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

const GET_COMPONENTS = gql`
  query($limit: Int, $start: Int, $sort: String, $where: JSON) {
    components(limit: $limit, start: $start, sort: $sort, where: $where) {
      id
      ShortDescription
      cover {
        url
      }
      category {
        name
      }
      name
    }
    componentsConnection(where: $where) {
      aggregate {
        count
      }
    }
    componentCategories {
      id
      name
    }
  }
`;

export { GET_COMPONENTS };
