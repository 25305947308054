/**
 *
 * H1
 *
 */

import styled from 'styled-components';

import colors from '../../assets/styles/colors';
import sizes from '../../assets/styles/sizes';

const H1 = styled.h1`
  font-size: 2rem;
  color: ${colors.black};
  line-height: normal;
  @media (min-width: ${sizes.tablet}) {
    font-size: 2.5rem;
  }
`;

export default H1;
