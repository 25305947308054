/**
 *
 * Card
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
  restaurantDefaultShape,
  restaurantShape,
} from '../../shapes/restaurant';

import StyledCard from './StyledCard';
import CardSection from '../ComponentCardSection';
import Img from '../Img';

function Card({ component, onClick }) {
  const { id, cover } = component;
  const coverURL = cover[0] ? cover[0].url : '';

  return (
    <StyledCard onClick={() => onClick(id)} className="clickable-card">
      <div className="img-wrapper">
        <Img src={`${coverURL}`} alt="cover" />
      </div>
      <CardSection component={component} />
    </StyledCard>
  );
}

Card.defaultProps = {
  ...restaurantDefaultShape,
};

Card.propTypes = {
  onClick: PropTypes.func.isRequired,
  ...restaurantShape,
};

export default Card;
