/**
 *
 * ComponentsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { set } from 'lodash';

import { GET_COMPONENTS } from '../../queries';
import Query from '../../components/Query';
import GetStarted from '../../components/GetStarted';

import getQueryParameters from '../../utils/getQueryParameters';

import RenderView from './RenderView';
import Filters from '../../components/Filters';

function ComponentsPage({ location: { search }, history }) {
  const start = parseInt(getQueryParameters(search, 'start'), 10) || 0;
  const orderby = getQueryParameters(search, 'orderby') || 'name';
  const range = 15;

  const setSearch = (where, nextStart) => {
    history.push({
      search: `?category=${where.category}&start=${nextStart}`,
    });
  };

  const getWhereParams = () => {
    const category = getQueryParameters(search, 'category') || 'all';
    const district = getQueryParameters(search, 'district') || '_all';

    return {
      category,
      district,
    };
  };

  const prepareWhereParams = () => {
    const where = getWhereParams();

    return Object.keys(where).reduce((acc, current) => {
      if (!!where[current] && !where[current].includes('all')) {
        acc[current] = where[current];
      }
      return acc;
    }, {});
  };

  const handleClick = id => history.push(`/component/${id}`);

  const handleChange = ({ target }) => {
    const where = getWhereParams();
    set(where, target.name, target.value);
    setSearch(where, 0);
  };

  const handlePageChange = ({ target }) => {
    setSearch(getWhereParams(), target.value);
  };

  const renderFilters = ({ componentCategories }) => {
    const filters = [
      // Uncomment when backend is available - V2
      // {
      //   title: 'Order by',
      //   name: 'orderby',
      //   options: ['ranking', 'name'],
      //   value: orderby,
      // },
      {
        title: 'Categories',
        name: 'category',
        options: [{ id: 'all', name: 'all' }, ...componentCategories],
        value: getQueryParameters(search, 'category') || 'all',
      },
    ];

    return <Filters filters={filters} onChange={handleChange} range={range} />;
  };

  const renderView = ({ components, ...rest }) => {
    return (
      <>
        {renderFilters(rest)}
        <RenderView
          components={components}
          onClick={handleClick}
          onPagingChange={handlePageChange}
          rest={rest}
          start={start}
          range={range}
        />
      </>
    );
  };

  return (
    <div className="page-wrapper">
      <div id="restaurants-page">
        <Container>
          <Query
            query={GET_COMPONENTS}
            render={renderView}
            variables={{
              limit: range,
              start,
              sort: `${orderby}:ASC`,
              where: prepareWhereParams(),
            }}
          />
        </Container>
      </div>
      <GetStarted />
    </div>
  );
}

ComponentsPage.defaultProps = {
  location: {
    pathname: null,
    search: null,
  },
};

ComponentsPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

export default ComponentsPage;
