/**
 *
 * StyledFooter
 *
 */

import styled from 'styled-components';

import colors from '../../assets/styles/colors';
import sizes from '../../assets/styles/sizes';

const StyledGetStarted = styled.div`
  .informations-wrapper {
    padding: 0 ${sizes.margin * 1.8}px 0;
  }
  .section-get-started {
    padding: 3em 0 !important;
    h1 {
      font-size: 2em !important;
      margin-bottom: 20px;
      padding: 0 !important;
    }
    .get-started-textbox {
      display: block;
    }
    .get-started-button {
      display: block;
      padding: 20px 0 30px 0;
      .get-started-buttons {
        display: inline-block;
      }
    }
    .ready-text {
      font-weight: 300;
      color: ${colors.lightOrange};
      margin-top: 20px;
    }
    .contact-button {
      padding: 15px;
      text-transform: uppercase;
      color: ${colors.lightOrange};
      background-color: white;
      border-radius: 4px;
      font-weight: 700;
      line-height: 1;
      border: 0;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  @media (min-width: ${sizes.tablet}) {
    .section-get-started {
      display: flex;
      align-items: center;
      .get-started-textbox {
        display: inline-block;
        width: 50%;
      }
      .get-started-button {
        display: inline-block;
        width: 50%;
        text-align: right;
        padding: 0;
      }
      .get-started-buttons {
        display: inline-block;
      }
    }
  }

  @media (min-width: ${sizes.desktop}) {
  }
`;

export default StyledGetStarted;
