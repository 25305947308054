/**
 *
 * StyledZammadButton
 *
 */

import styled from 'styled-components';

import sizes from '../../assets/styles/sizes';

const StyledZammadButton = styled.div`
  .zammad-button {
    h4 {
      font-size: 1.5em;
      font-weight: 700;
    }
    p {
      font-size: 1em !important;
    }
  }

  @media (min-width: ${sizes.tablet}) {
  }

  @media (min-width: ${sizes.desktop}) {
  }
`;

export default StyledZammadButton;
