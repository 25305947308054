const sizes = {
  tablet: '768px',
  desktop: '992px',
  wide: '1200px',

  margin: 10,
  header: {
    height: {
      small: '70px',
      large: '84px',
    },
    logoWidth: {
      small: '150px',
      large: '170px',
    },
    padding: '18px',
  },
  article: {
    img: {
      height: '176px',
    },
  },
  slider: {
    height: {
      small: '172px',
      large: '356px',
    },
    controls: {
      size: '68px',
    },
    indicators: {
      size: '74px',
    },
  },
  input: {
    height: '44px',
  },
  cover: {
    height: {
      small: '250px',
      large: '500px',
      wide: '600px',
      tall: '800px',
    },
  },
  section: {
    height: {
      small: '250px',
      medium: '350px',
      large: '500px',
      wide: '600px',
      tall: '750px',
    },
  },
  social: {
    size: '24px',
  },
};

export default sizes;
