import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const About = createGlobalStyle`
  #contact-page {
    h1 {
      margin-bottom: ${sizes.margin * 2}px;
    }
    h4 {
      margin-bottom: ${sizes.margin * 1.6}px;
      font-size: 1.5em;
      font-weight: 700;
    }
    .text-section {
      padding-bottom: ${sizes.margin * 7.4}px;
      p {
        font-size: 1.05em;
        line-height: 1.5;
        color: ${colors.black};
      }
    }
    ul {
      list-style-type: initial;
      margin-top: ${sizes.margin * 1.6}px;
      margin-left: ${sizes.margin * 1.8}px;
    }
    .cover-wrapper, .informations-wrapper {
      position: relative;
    }
    .cover-wrapper {
      z-index: 0;
      background-color: ${colors.greyBkdg};
      padding: 5rem ${sizes.margin * 1.8}px 5rem;
      h1 {
        font-size: 2.75em;
      }
      p {
        font-size: 1.25em;
        line-height: 2rem;
      }
      .cover-text {
        max-width: 800px;
      }
    }
    .informations-wrapper {
      padding: 1em ${sizes.margin * 1.8}px 5em;
      .container {
        .column {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          padding: 2em 0 0 0;
          img {
            width: 20%;
            display: inline-block;
          }
          .contact-text {
            width: 80%;
            display: inline-block;
            vertical-align: middle;
            padding-left: 1em;
          }
          :hover {
            h4 {
            color: ${colors.lightPurple};
            }
            p {
            color: ${colors.lightPurple};
            }
          }
        }
      }
    }
    @media (min-width: ${sizes.tablet}) {
      .cover-wrapper {
      }
      .informations-wrapper {
        .container {
          .column {
            width: calc(100% / 2);
            padding: 2em 3em 0 0;
            img {
              width: 100%;
              padding-right: 50%;
            }
            .contact-text {
              width: 100%;
              padding-left: 0;
            }
          }
        }
      }
    }
    @media (min-width: ${sizes.desktop}) {
      .cover-wrapper {
      }
      .informations-wrapper {
        .container {
          .column {
            width: calc(100% / 3);
          }
        }
      }
    }
  }
`;

export default About;
