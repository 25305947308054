/**
 *
 * App
 *
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyles from '../../components/GlobalStyles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Home from '../Home';
import Stack from '../Stack';
import Contact from '../Contact';
import ComponentsPage from '../ComponentsPage';
import ComponentPage from '../ComponentPage';
import Prices from '../Prices';
import About from '../About';
import NotFound from '../NotFound';

const headerUrls = [
  { name: 'Stack', to: '/stack' },
  { name: 'Components', to: '/components' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'About', to: '/about' },
];
const footerUrls = [
  { name: 'Stack', to: '/stack' },
  { name: 'Components', to: '/components' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'About', to: '/about' },
  { name: 'Contact', to: '/contact' },
];

function App() {
  return (
    <div>
      <GlobalStyles />
      <Header links={headerUrls} />
      <ScrollToTop>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/stack" component={Stack} exact />
          <Route path="/contact" component={Contact} exact />
          <Route path="/components" component={ComponentsPage} exact />
          <Route path="/component/:id" component={ComponentPage} exact />
          <Route path="/pricing" component={Prices} exact />
          <Route path="/use-cases" component={Prices} exact />
          <Route path="/about" component={About} exact />
          <Route path="/blog" component={About} exact />
          {/* <Route path="/:id/:content" component={RestaurantPage} exact /> */}
          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
      <Footer links={footerUrls} />
    </div>
  );
}

App.defaultProps = {};
App.propTypes = {};

export default App;
