/**
 *
 * About
 *
 */

import React from 'react';

import H1 from '../../components/H1';
import Img from '../../components/Img';
import GetStarted from '../../components/GetStarted';

import imgMathias from '../../assets/img/mathiasg.png';
import imgTom from '../../assets/img/tom.jpg';
import imgTrevor from '../../assets/img/trevor.jpg';
import imgKim from '../../assets/img/kim.jpg';
import imgAllan from '../../assets/img/allan.jpg';
import imgCaio from '../../assets/img/caio.jpg';
import imgEliot from '../../assets/img/eliot.jpg';
import imgBen from '../../assets/img/ben.png';

function About() {
  return (
    <div className="page-wrapper" id="about-page">
      <div className="cover-wrapper">
        <div className="container">
          <div className="cover-text">
            <H1>
              <b>We set new standards for markets</b>
            </H1>
            <p>
              By lowering barriers to entry for market operators, and
              encouraging an ecosystem to form by not operating a walled garden,
              we redefine what markets can be.
            </p>
          </div>
          <div className="cover-media"></div>
        </div>
      </div>
      <div className="informations-wrapper">
        <div className="container about-content">
          <H1>
            <b>Our Story</b>
          </H1>
          <p>
            Our Mission: To bring about a world where everyone can participate
            in competitive free markets.
          </p>
          <div className="story-section">
            <div className="left-story">
              <p>
                <b>The Market Infrastructure Ecosystem</b>
              </p>
              <br />
              <p>
                The increased regulatory burden put on market operators through
                the past decades has resulted in a decline in competition and
                the quality of service. It has never been harder for investors
                to invest in what they want, despite the vast technological
                developments that have taken place in the industry.
              </p>
              <br />
              <p>
                We believe that people have a right to participate in free
                markets, but ultimately we realized we would have to rebuild the
                financial system from the ground up to facilitate this change.
                This is why the founder of Braveno - Mathias Groennebaek, helped
                found Ethereum to bring about a shift in the tendency towards
                centralization.
              </p>
              <br />
              <p>
                In 2015 we started building Braveno. Market infrastructure is
                known as one of the hardest software systems to build, because
                it requires balancing several counteracting characteristics. It
                took us 3 years, but by 2018 we had a working platform.
              </p>
              <br />
              <p>
                With the platform ready for operation, we finally reached the
                milestone where we could start onboarding early adopters. Since
                then we have worked with companies to issue alternative assets,
                and set up niche markets, and today we are bringing a well
                tested software stack to market, and building an ecosystem
                around it.
              </p>
            </div>
            <div className="right-story">
              <p>
                <b>A Growing Ecosystem</b>
              </p>
              <br />
              <p>
                Braveno is now setting a new standard for how markets should
                function, a modularized infrastructure where market operators
                can tap into an ecosystem of services, and providers can offer
                their services to multiple market operators.
              </p>
              <br />
              <p>
                The ecosystem already has all the tools you need for operating a
                market, and innovative service providers are steadily joining to
                provide an even wider selection of tools that will help improve
                your market.
              </p>
              <hr />
              <p>
                <b>Beyond Markets</b>
              </p>
              <br />
              <p>
                The new standards set by Braveno are driving new opportunities:
                new ways to use Braveno’s technology to improve performance and
                inspire innovation, new arenas where Bravenos commitment to
                lowering barriers to entry, and towards openness and
                transparency can make a difference, and help drive the financial
                system towards everybody in the world having access to high
                quality markets.
              </p>
            </div>
          </div>
          <H1>
            <b>The People</b>
          </H1>
          <p>
            At Braveno, we are convinced that achieving our mission begins with
            the standards we set for ourselves.
          </p>
          <br />
          <p>
            <b>Be good - Innovate - Be open - Lead the way</b>
          </p>
          <div className="people-section">
            <div className="people-img-section">
              <Img className="people-img" src={imgMathias} />
              <Img className="people-img" src={imgTom} />
              <Img className="people-img" src={imgTrevor} />
              <Img className="people-img" src={imgKim} />
              <Img className="people-img" src={imgAllan} />
              <Img className="people-img" src={imgCaio} />
              <Img className="people-img" src={imgEliot} />
              <Img className="people-img" src={imgBen} />
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default About;
