import { createGlobalStyle } from 'styled-components';

import sizes from '../../../assets/styles/sizes';
import colors from '../../../assets/styles/colors';

const Component = createGlobalStyle`
  #component-page {
    padding: 0 ${sizes.margin * 1.8}px 0;
    .back-wrapper {
      padding-top: 10px;
      .back-link {
        padding: 10px 0;
        vertical-align: top;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 300;
        color: #773b97;
      }
    }
    .intro-wrapper {
      z-index: 1;
      width: 100%;
      padding: 2em 0 2em 0;
      border-bottom: 2px solid ${colors.greyBorder};
      position: relative;
      ul {
        background-color: white;
        li.column:nth-child(3n + 1) {
          padding-left: inherit;
        }
      }
      h4 {
        font-size: 1.25em;
        padding-top: 20px;
        font-weight: 300;
        line-height: 1.5em;
      }
    }
    .informations-wrapper {
      overflow: visible;
      padding-top: 2em;
      padding-bottom: 2em;
      background-color: ${colors.white};
      h1 {
        padding: 0 0 ${sizes.margin * 1.4}px 0;
      }
      h4 {
        padding-bottom: 10px;
      }
      img {
        max-width: 100%;
      }
      .screenshot {
        text-align: center;
        padding: 3em 0 6em 0;
        img {
          box-shadow: 0 50px 50px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3);
          height: auto;
          max-width: 100%;
          border-radius: 1em;
        }
      }
    }
    @media (min-width: ${sizes.tablet}) {
      .intro-wrapper {
        padding: 5em 0 5em 0;
        ul {
          padding: 0;
        }
      }
      .informations-wrapper {
        padding: 3em 0 6em 0;
        .content-left {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          padding: 2em 1em 2em 0;
        }
        .content-left.screenshot {
          transform: perspective(1000px) scale(1) rotateX(2deg) rotateY(13deg) rotateZ(0deg);
          transform-origin: center right;
        }
        .content-right {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          padding: 2em 0 2em 1em;
        }
        .content-right.screenshot {
          transform: perspective(1000px) scale(0.88) rotateX(2deg) rotateY(-13deg);
          transform-origin: center left;
        }
        .screenshot {
          img {
            max-width: 80%;
          }
        }
        .mobile-screenshot {
          img {
          }
        }
      }
    }
    @media (min-width: ${sizes.wide}) {
      .intro-wrapper {
        padding: 5em 0 5em 0;
        ul {
          padding: 0;
        }
      }
      .informations-wrapper {
        padding: 6em 0 6em 0;
        .content-left {
          padding: 1em 1em 1em 0;
        }
        .content-right {
          padding: 1em 0 1em 1em;
        }
      }
    }
  }
`;

export default Component;
