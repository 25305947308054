import React from 'react';
import General from './General';
import Font from './Font';

import Home from './pages/Home';
import Stack from './pages/Stack';
import Component from './pages/Component';
import Components from './pages/Components';
import Prices from './pages/Prices';
import Restaurant from './pages/Restaurant';
import Restaurants from './pages/Restaurants';
import About from './pages/About';
import Contact from './pages/Contact';

function GlobalStyles() {
  return (
    <React.Fragment>
      <Font />
      <General />
      <Home />
      <Stack />
      <Component />
      <Components />
      <Prices />
      <Restaurant />
      <Restaurants />
      <About />
      <Contact />
    </React.Fragment>
  );
}

export default GlobalStyles;
