/**
 *
 * StyledDemoButton
 *
 */

import styled from 'styled-components';

import colors from '../../assets/styles/colors';
import sizes from '../../assets/styles/sizes';

const StyledDemoButton = styled.div`
  .demo-button-wrapper {
    .demo-button {
      padding: 15px;
      margin-right: 20px;
      text-transform: uppercase;
      color: white;
      background-color: ${colors.lightOrange};
      border-radius: 4px;
      font-weight: 700;
      line-height: 1;
      border: 0;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  @media (min-width: ${sizes.tablet}) {
    .demo-button-wrapper {
      display: inline-block !important;
    }
  }

  @media (min-width: ${sizes.desktop}) {
  }
`;

export default StyledDemoButton;
