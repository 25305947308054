/**
 *
 * Footer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { Link } from 'react-router-dom';

import StyledFooter from './StyledFooter';

function Footer({ links }) {
  return (
    <StyledFooter>
      <div className="container">
        <Nav navbar>
          <div className="nav-list">
            {links.map(link => {
              return (
                <Link
                  key={link.name}
                  to={link.to}
                  className={
                    link.name === 'My account' || link.name === 'Favorites'
                      ? 'main-link'
                      : ''
                  }
                >
                  <span title={link.name}>{link.name}</span>
                </Link>
              );
            })}
          </div>
        </Nav>
        <div className="love-lbl">
          <p>© Braveno LTD 2020</p>
        </div>
      </div>
    </StyledFooter>
  );
}

Footer.defaultProps = {
  links: [],
};
Footer.propTypes = {
  links: PropTypes.array,
};

export default Footer;
