/**
 *
 * Prices
 *
 */

import React from 'react';
import { Table } from 'reactstrap';

import H1 from '../../components/H1';
import GetStarted from '../../components/GetStarted';
import DemoButton from '../../components/DemoButton';

function Prices() {
  return (
    <div className="page-wrapper" id="prices-page">
      <div className="cover-wrapper">
        <div className="container">
          <div className="cover-text">
            <H1>
              <b>The best price in the market</b>
            </H1>
            <p>Braveno Market-As-A-Service (MaaS)</p>
          </div>
        </div>
        <div className="background"></div>
        <div className="container price-wrapper">
          <div className="price-column-1 price-column background-lightGrey">
            <div>
              <p>
                <br />
                &nbsp;
              </p>
            </div>
            <div className="price-content">
              <h1>
                <b>€200</b>
              </h1>
              <p>
                per month
                <br />
                (minimum)
              </p>
            </div>
            <div className="price-separator">
              <h4>or</h4>
            </div>
            <div className="price-content">
              <h1>
                <b>30%</b>
              </h1>
              <p>
                of trading revenue
                <br />
                (when &gt;€200)
              </p>
            </div>
            <hr />
            <h4>
              <b>Single order book</b>
            </h4>
            <p className="min-fee">
              Minimum 3% total trading fee.
              <br />
              Eg. 1% maker fee, and 2% taker fee.
            </p>
          </div>
          <div className="price-column-2 price-column background-mediumGrey">
            <div>
              <p>
                * All prices are negotiable on markets of significant volume
                <br />
                <br />
              </p>
            </div>
            <div className="price-content">
              <h1>
                <b>€5000</b>
              </h1>
              <p>
                per month
                <br />
                (minimum)
              </p>
            </div>
            <div className="price-separator">
              <h4>or</h4>
            </div>
            <div className="price-content">
              <h1>
                <b>10%</b>
              </h1>
              <p>
                of market fee revenue
                <br />
                (when &gt;€5000)
                <br />
              </p>
            </div>
            <hr />
            <h4>
              <b>Unlimited order books</b>
            </h4>
            <p className="min-fee">
              Minimum 0.3% total trading fee.
              <br />
              Eg. 0.1% maker fee, and 0.2% taker fee.
            </p>
          </div>
        </div>
        <div className="container terms-box">
          <p>
            All prices shown <b>exclude</b> VAT
          </p>
          <p>MaaS is subject to a 30 day minimum term</p>
        </div>
      </div>
      <div className="informations-wrapper background-Orange">
        <div className="container">
          <H1>What&apos;s included</H1>
          <div className="text-section">
            <ul>
              <li>Full access to Braveno Stack</li>
              <li>Admin panel</li>
              <li>Open source realtime react.js based responsive webapp</li>
              <li>Open source mobile apps for Android and iOS</li>
              <li>Unlimited market data</li>
              <li>Unlimited API calls</li>
              <li>Access to Braveno and 3rd party components</li>
            </ul>
            <div className="styled-button-box">
              <DemoButton />
            </div>
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-white">
        <div className="container price-section">
          <H1>Transaction settlement</H1>
          <div className="text-section">
            <p>
              Braveno offers both digital ledger and traditional settlement and
              clearing for your market. In order to maintain a transparent and
              fully auditable transactional trail to expose to your users,
              digital ledger can sometimes be a good choice.
            </p>
            <br />
            <p>
              While Braveno does not charge you anything for settlement,
              digital ledger networks and external service providers might. Braveno
              have a custom built bulk settlement infrastructure for digital ledgers
              that facilitates doing this as cheaply as possible, which can be
              configured to optimise fee costs according to user throughput
              (size, amount, number of trades).
            </p>
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-mediumGrey">
        <div className="container price-section">
          <H1>Support plans</H1>
          <div className="text-section">
            <p>We offer additional plans for in-house support.</p>
            <br />
            <p>
              Basic support is included for all Braveno customers and includes:
            </p>
            <ul>
              <li>Email and ticket support</li>
              <li>API Documentation & Knowledge Base</li>
              <li>Braveno Services Health Dashboard</li>
            </ul>
            <br />
            <div className="support-plan-table table-responsive">
              <Table className="table-striped">
                <caption>
                  <br />
                  <p>
                    All prices shown <b>exclude</b> VAT
                  </p>
                  <p>Support plans are subject to a 30 day minimum term</p>
                  <p>
                    *We will make every reasonable effort to respond to your
                    initial request within the corresponding timeframes
                  </p>
                  <p>
                    **Business hours are defined as 8:00 AM to 6:00 PM London
                    Time
                  </p>
                </caption>
                <thead>
                  <tr>
                    <th className="table-title-column"></th>
                    <th className="table-cell-column">Intermediate</th>
                    <th className="table-cell-column">Advanced</th>
                    <th className="table-cell-column">Institutional</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td style={{ fontWeight: 300 }}>
                      Recommended if you are operating a market, or if you are
                      experimenting or developing services.{' '}
                    </td>
                    <td style={{ fontWeight: 300 }}>
                      Recommended if you are operating a market customized with
                      third party services.
                    </td>
                    <td style={{ fontWeight: 300 }}>
                      Recommended if you are operating a highly mission critical
                      market with a high degree of customization.
                    </td>
                  </tr>
                  <tr>
                    <th>Enhanced Technical Support Access</th>
                    <td>
                      <p>Unlimited cases</p>
                      <p>Support engineer via email/ticket system</p>
                    </td>
                    <td>
                      <p>Unlimited cases</p>
                      <p>Support engineer via email/ticket system/chat</p>
                      <p>Business hours** phone support</p>
                    </td>
                    <td>
                      <p>Unlimited cases</p>
                      <p>Support engineer email/ticket system/chat</p>
                      <p>24/7 phone support</p>
                      <p>Designated technical support concierge</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Case Severity / Response Times*</th>
                    <td>
                      <p>General guidance: &lt; 24 business hours**</p>
                      <p>System impaired: &lt; 12 business hours**</p>
                    </td>
                    <td>
                      <p>General guidance: &lt; 24 hours</p>
                      <p>System impaired: &lt; 12 hours</p>
                    </td>
                    <td>
                      <p>General guidance: &lt; 12 hours</p>
                      <p>System impaired: &lt; 6 hours</p>
                      <p>Business-critical system down: &lt; 1 hour</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Application Guidance</th>
                    <td>General</td>
                    <td>Contextual to your use case</td>
                    <td>Consultative review and based on your application</td>
                  </tr>
                  <tr>
                    <th>Third Party Software Support</th>
                    <td>Paid</td>
                    <td>Interoperability & configuration guidance</td>
                    <td>
                      Interoperability & configuration guidance and
                      troubleshooting
                    </td>
                  </tr>
                  <tr>
                    <th>Research & Development Rate</th>
                    <td>€150/hour</td>
                    <td>€140/hour</td>
                    <td>€130/hour</td>
                  </tr>
                  <tr>
                    <th>Pricing</th>
                    <td>
                      <p>Greater of €100/month</p>
                      <p>-or-</p>
                      <p>5% of monthly MaaS fees</p>
                    </td>
                    <td>
                      <p>Greater of €1000/month</p>
                      <p>-or-</p>
                      <p>10% of monthly MaaS fees</p>
                    </td>
                    <td>
                      <p>Greater of €10000/month</p>
                      <p>-or-</p>
                      <p>15% of monthly MaaS fees</p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="informations-wrapper background-white">
        <div className="container price-section">
          <H1>Bespoke research and development</H1>
          <div className="text-section">
            <p>
              Braveno can also offer custom bespoke development and research if
              your needs exceed the existing stack. Contact us to discuss your
              project, we always love to talk about new exciting ways to use our
              technology and improve our ecosystem.
            </p>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default Prices;
