/**
 *
 * CardSection
 *
 */

import React from 'react';

import H1 from '../H1';
import H4 from '../H4';

import StyledCardSection from './StyledCardSection';

function CardSection({ component, hasLink }) {
  const { category, name, ShortDescription } = component;

  return (
    <StyledCardSection className={hasLink && 'banner'}>
      <div className="left-infos">
        {!hasLink ? <H4>{name}</H4> : <H1>{name}</H1>}
      </div>
      <div className="right-infos">
        <p>{category && <span>{category.name}</span>}</p>
      </div>
      <div className="bottom-infos">
        {!hasLink ? (
          <p className="description">{ShortDescription}</p>
        ) : (
          <H4>{ShortDescription}</H4>
        )}
      </div>
    </StyledCardSection>
  );
}

export default CardSection;
