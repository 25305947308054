// @flow
import React from 'react';
import H4 from '../H4';
import Img from "../Img";

import StyledZammadButton from './StyledZammadButton';

import imgCustomerService from '../../assets/img/customer-service.svg';

const $ = window.$;

export class ZammadButton extends React.Component {
  componentDidMount() {
    this.$el = $(this.el);
    this.$el.ZammadForm({
      messageTitle: 'General Support',
      messageSubmit: 'Submit',
      messageThankYou:
        "Thank you for your inquiry (#%s)! We'll contact you as soon as possible.",
      showTitle: true,
      modal: true,
    });
  }

  render() {
    return (
      <StyledZammadButton className="column">
        <a
          ref={el => (this.el = el)}
          className="zammad-button"
          id="technical-support-form"
        >
          <Img src={imgCustomerService} />

          <div className="contact-text">
            <H4>I need general support</H4>
            <p>Get help from an account manager with payments or your account</p>
          </div>
        </a>
      </StyledZammadButton>
    );
  }
}

export default ZammadButton;
