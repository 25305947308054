/**
 *
 * Error
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Error() {
  return (
    <div>
      Error
    </div>
  );
}

Error.defaultProps = {};
Error.propTypes = {};

export default Error;
