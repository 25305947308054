/**
 *
 * StyledFooter
 *
 */

import styled from 'styled-components';

import colors from '../../assets/styles/colors';
import sizes from '../../assets/styles/sizes';
import fonts from '../../assets/styles/fonts';

const StyledFooter = styled.div`
  padding: ${sizes.margin * 2.1}px ${sizes.margin * 2}px ${sizes.margin * 1.8}px
    ${sizes.margin * 2}px;
  background-color: ${colors.lightGrey};
  .navbar-brand {
    width: 50%;
    vertical-align: top;
    display: inline-block;
    padding: 0;
    margin: 36px 0;
    text-align: center;
  }
  .contact-us {
    width: 50%;
    vertical-align: top;
    margin: 0;
    display: inline-block;
    text-align: center;
    button {
      height: ${sizes.input.height};
      background-color: ${colors.lightOrange};
      display: inline;
      border: 0;
      color: white;
      margin: 30px 0;
      padding: 0 15px;
      img {
        width: 8px;
        height: 8px;
        margin: auto;
      }
    }
  }
  .nav-list a,
  .newsletter .newsletter-lbl {
    ${fonts.reg};
    font-size: 12px;
    color: ${colors.darkGrey};
    text-align: center;
  }
  .nav-list {
    margin-bottom: 1em;
    a {
      display: inline-block;
      width: 50%;
      line-height: 2.67;
      &:hover {
        text-decoration: none;
        color: ${colors.darkBlue};
        ${fonts.bold};
      }
    }
  }
  .newsletter {
    padding-bottom: ${sizes.margin}px;
    .newsletter-lbl {
      line-height: 2.67;
      margin-bottom: ${sizes.margin * 1.7}px;
    }
    .input-wrapper {
      position: relative;
      button {
        position: absolute;
        top: 0;
        right: 0;
        width: ${sizes.input.height};
        height: ${sizes.input.height};
        background-color: ${colors.lightOrange};
        display: flex;
        border: 0;
        img {
          width: 8px;
          height: 8px;
          margin: auto;
        }
      }
    }
  }
  .love-lbl {
    ${fonts.reg};
    font-size: 12px;
    color: ${colors.darkBlue};
    width: 100%;
    text-align: center;
    padding-top: ${sizes.margin * 2.3}px;
  }

  @media (min-width: ${sizes.tablet}) {
    padding-top: ${sizes.margin * 5.8}px;
    .navbar-brand {
      margin: 0;
      padding: 0 0 3rem 0;
      width: 100%;
      float: left;
      text-align: left;
    }
    .contact-us {
      width: 50%;
      float: left;
      text-align: right;
      margin-bottom: 40px button {
        margin: 0;
        display: inline;
      }
    }
    .nav-list {
      max-width: 800px;
      margin: 0 auto;
      padding-bottom: 2em;
      a {
        width: calc(100% / 3);
        margin-bottom: ${sizes.margin * 0.4}px;
        text-align: center;
      }
    }
    .navbar-nav,
    .newsletter {
      display: inline-block;
      width: 100%;
      vertical-align: top;
    }
  }

  @media (min-width: ${sizes.desktop}) {
    .nav-list {
      a {
        width: calc(100% / 5);
      }
      .main-link {
        float: right;
        clear: both;
      }
    }
    .navbar-nav {
      width: 100%;
    }
    .newsletter {
      width: 48.7%;
      padding-left: 8.6%;
    }
  }
`;

export default StyledFooter;
